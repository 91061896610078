import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/react-hooks';
import * as _ from 'lodash';
import { Helmet } from 'react-helmet';
import MaterialTable from 'material-table';
import { GET_VASP_REQUEST_LOG } from '../crud';
import { checkJWTExpired, getToken, parseGraphQLErrorMessage, navigate } from '../utils/utils';
import { ErrorDialog, VASPTitleTypography } from '../components/common';
import Layout from '../components/layout';
import Navigator from '../components/navigator';
import AuthenticatedPage from '../components/authenticated-page';
import NotFoundPage from './404';
import $ from '../config';

function MonitoringVASP({ location }) {
  if ($.GATSBY_ENV === 'prod') {
    return NotFoundPage();
  }
  const targetVASPCode = _.get(location, ['state', 'parameter', 'vasp_code'], undefined);
  const targetVASPName = _.get(location, ['state', 'parameter', 'vasp_name'], undefined);

  if (!targetVASPCode || !targetVASPName) {
    navigate('/');
    return '';
  }

  const token = getToken();
  const [requestLogs, setRequestLogs] = useState([]);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [error, setError] = useState('');

  const [getVASPRequestLog] = useLazyQuery(GET_VASP_REQUEST_LOG, {
    variables: {
      vasp_code: targetVASPCode,
    },
    context: {
      headers: {
        authorization: token,
      },
    },
    onCompleted: (data) => {
      if (!data) {
        setErrorDialogOpen(true);
        setError(parseGraphQLErrorMessage('data is undefined'));
        return;
      }
      if (_.has(data, 'getVaspRequestLog')) {
        const clone = _.cloneDeep(data.getVaspRequestLog);
        clone.sort((a, b) => {
          const pathA = a.path.toUpperCase();
          const pathB = b.path.toUpperCase();
          if (pathA < pathB) {
            return -1;
          }
          if (pathA > pathB) {
            return 1;
          }
          return 0;
        });
        setRequestLogs(clone);
      } else {
        setRequestLogs([]);
      }
    },
    onError: (e) => {
      setErrorDialogOpen(true);
      setError(parseGraphQLErrorMessage(e));
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (token && !checkJWTExpired(token)) {
      getVASPRequestLog();
    }
  }, []);

  return (
    <Layout>
      <AuthenticatedPage>
        <div className="application">
          <Helmet>
            <meta charSet="utf-8" />
            <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
          </Helmet>
        </div>
        <Navigator
          breadcrumbTexts={['Home', 'Monitoring', 'Monitoring Vasp']}
          breadcrumbLinks={['/', '/monitoring-list', '/monitoring-vasp']}
        />
        <VASPTitleTypography vaspCode={targetVASPCode} vaspName={targetVASPName} />
        <MaterialTable
          title=""
          columns={[
            {
              title: 'Task',
              field: 'path',
              headerStyle: { paddingLeft: '1em' },
              cellStyle: { paddingLeft: '1em' },
            },
            {
              title: 'Complete',
              field: 'complete',
              width: 100,
              render: (rowData) => {
                if (!rowData.last_request_at) {
                  return (
                    <span
                      style={{
                        height: '25px',
                        width: '25px',
                      }}
                    />
                  );
                }
                return (
                  <span
                    style={{
                      height: '25px',
                      width: '25px',
                      backgroundColor: rowData.complete ? 'green' : 'red',
                      borderRadius: '50%',
                      display: 'inline-block',
                    }}
                  />
                );
              },
            },
            { title: 'Last Request Time', field: 'last_request_at' },
            { title: 'Number of all the Requests', field: 'request_count' },
          ]}
          data={requestLogs}
        />
        <ErrorDialog open={{ get: errorDialogOpen, set: setErrorDialogOpen }} message={error} />
      </AuthenticatedPage>
    </Layout>
  );
}

export default MonitoringVASP;

MonitoringVASP.defaultProps = {
  location: undefined,
};

MonitoringVASP.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      parameter: PropTypes.shape({
        vasp_code: PropTypes.string,
        vasp_name: PropTypes.string,
      }),
    }),
  }),
};
